* {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  position: relative;
  height: 100vh;
  background: linear-gradient(
    to bottom,
    hsl(273, 75%, 66%),
    hsl(240, 73%, 65%)
  );
}

/* Accordeon Style */
.acc-style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  min-height: 60vh;
  background: #fff;
  border-radius: 15px;
  padding: 1em 0;
}

/* Question Component */

.div-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8em 0;
  border-bottom: 1px solid #eee;
  width: 100%;
}

.div-flex h4 {
  width: 100%;
  text-align: left;
  padding-left: 1em;
  color: #333;
}

.div-flex span {
  color: orangered;
  font-size: 1.5rem;
  padding-right: 0.5em;
}

.panel {
  background: #eee;
  border-radius: 5px;
  padding: 0.8em 0;
  transition: 0.3s;
}

.panel p {
  font-size: 14px;
  width: 100%;
}

@media (min-width: 1024px) {
  .acc-style {
    max-width: 900px;
  }

  .div-flex {
    padding: 1em 0;
  }

  .div-flex h4 {
    width: 100%;
    font-size: 1.3rem;
  }

  .div-flex span {
    color: orangered;
    font-size: 1.8rem;
    padding-right: 0.5em;
  }

  .panel {
    background: #eee;
    border-radius: 5px;
    text-align: left;
    padding-left: 1em;
  }

  .panel p {
    font-size: 1.1rem;
  }
}
